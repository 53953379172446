<template>
  <div id="app-2">
        <h1>Hello {{ fullname }}</h1>
        <h2>{{ title }} </h2>
        <input v-model="title">
        <h2>Task List</h2>
        <ul id="task-list">
          <li v-for="task in tasks" :key="task.title">
            {{ task.title }}
          </li>
        </ul>
  </div>                                                                                                                                                                                                   
</template>
<script>
module.exports = {
  data: function() {
       return {
          title: 'Our Hello World Title',
          firstname: 'Joe',
          lastname: 'Smith',
          tasks: [
              { title: 'Clean my room' },
              { title: 'Go to the grocery' },
              { title: 'meeting with JP' },
              { title: 'Go to the dentist' }
          ]
        }
  },
  computed: {
    fullname: function () {
      return this.firstname + ' ' + this.lastname
    }
  }
}

</script>

<style>
#app-2 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
